import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../../domain/theme';
import { getCardDatum } from '../shared/getCardDatum';
import { CardItem } from '../../../Card/CardItem';
import { leftProminentStyle } from './leftProminentStyle';
import { mapContentToCardData } from '../../../../domain/utils/contentUtils';
import { ImageSimple } from '../../../Image/ImageSimple';
import { imageResizerService } from '../../../../domain/services/imageResizerService';
import { SmartLink } from '../../../SmartLink';
import { SponsoredTag } from '../../../SponsoredTag';

export const LeftContentPackage = ({
  data,
  backgroundColor,
  hideDescription,
  isAboveTheFold
}) => {
  const styles = {
    aside: css`
      background-color: ${backgroundColor};
      display: grid;
      padding: 24px 24px 32px;
    `,
    title: css`
      display: -webkit-box;
      margin-bottom: 30px;
      color: ${backgroundColor === '#222' ? 'white' : 'black'};
      font-size: 2rem;
      ${theme.font.family('boldWeb')};
      line-height: 2.375rem;
      letter-spacing: 3px;
      text-transform: uppercase;
      padding-left: 24px;
      position: relative;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      ::before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;
        background-color: #fc0;
      }
    `,
    listContainer: css`
      display: grid;
      gap: 24px;
      padding-top: 32px;
    `,
    listItem: css`
      display: grid;
      grid-template-columns: 84px 1fr;
      gap: 24px;
    `,
    listItemImage: css`
      align-self: center;
      img {
        border-radius: 50%;
      }
    `,
    listItemText: css`
      position: relative;
      display: grid;
      gap: 8px;
      padding-bottom: 24px;
    `,
    listItemTitle: css`
      display: -webkit-box;
      overflow: hidden;
      color: ${backgroundColor === '#222' ? 'white' : 'black'};
      a {
        color: inherit;
      }
      font-size: 1rem;
      ${theme.font.family('medium')};
      line-height: 1.5rem;
      letter-spacing: 0.1px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      :hover {
        text-decoration: underline;
      }
    `,
    taxonomy: css`
      display: grid;
      grid-template-columns: auto 1fr;
      color: grey;
      a {
        color: inherit;
        :hover {
          text-decoration: underline;
        }
      }
      font-size: 0.75rem;
      ${theme.font.family('boldWeb')};
      line-height: 1rem;
      letter-spacing: 3px;
      text-transform: uppercase;
      span:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span:not(:first-of-type) {
        margin-left: 15px;
        padding-left: 15px;
        border-left: 2px solid currentColor;
      }
    `,
    sponsored: css`
      div {
        background-color: ${backgroundColor === '#222' ? 'white' : 'black'};
        color: ${backgroundColor === '#222' ? 'black' : 'white'};
      }
    `
  };

  const [prominentPositionCardData] = getCardDatum([data.prominentPosition], {
    hideDescription
  });
  const contentListCardData = data.contentList?.map(item => {
    // data can be an already mapped object coming from src/components/HubPage/TermViewHubpage.jsx
    // In such case, do not try to map it again.
    if (item.props) {
      return item.props.data;
    }
    return mapContentToCardData(item.entity, [
      'taxonomies',
      'videoDuration',
      'sponsored'
    ]);
  });

  const prominentStyle = leftProminentStyle(backgroundColor);

  return (
    <aside css={styles.aside}>
      {data.contentPackageHeader && (
        <h2 css={styles.title}>{data.contentPackageHeader}</h2>
      )}

      {prominentPositionCardData && (
        <CardItem
          styles={prominentStyle}
          data={prominentPositionCardData}
          aspect="16x9"
          canBeAnimated
          imageMaxWidths={{
            mobile: 719,
            tablet: 345,
            small_desktop: 347,
            large_desktop: 363
          }}
          isAboveTheFold={isAboveTheFold}
        />
      )}

      <ul css={styles.listContainer}>
        {contentListCardData?.map(card => {
          const ptt =
            card.taxonomies && card.taxonomies.find(term => term.isPtt);

          const headerItems = [];
          if (card.sponsored) {
            headerItems.push(
              <div css={styles.sponsored} key="sponsored">
                {/* Do not pass bgColor to SponsoredTag, to be able to style it from src/components/Paragraph/ContentPackage/style/*.js */}
                <SponsoredTag
                  text={card.sponsored.text}
                  url={card.sponsored.url}
                />
              </div>
            );
          } else if (ptt && ptt.name) {
            headerItems.push(
              <span key={ptt.name}>
                {ptt.url ? (
                  <SmartLink to={ptt.url}>{ptt.name}</SmartLink>
                ) : (
                  ptt.name
                )}
              </span>
            );
          }

          return (
            <li css={styles.listItem} key={card.url}>
              <div css={styles.listItemImage}>
                {card.imageEntity?.mediaImage?.url && (
                  <ImageSimple
                    src={imageResizerService.getStyleUrl(
                      card.imageEntity.mediaImage.url,
                      80,
                      80
                    )}
                    width={80}
                    height={80}
                    alt={card.title}
                    loading={isAboveTheFold ? 'lazy' : 'eager'}
                  />
                )}
              </div>
              <div css={styles.listItemText}>
                {headerItems.length > 0 && (
                  <div css={styles.taxonomy}>
                    {headerItems.map(headerItem => headerItem)}
                  </div>
                )}
                <div css={styles.listItemTitle}>
                  <SmartLink to={card.url}>{card.title}</SmartLink>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

LeftContentPackage.defaultProps = {
  isAboveTheFold: false,
  hideDescription: false
};

LeftContentPackage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  hideDescription: PropTypes.bool,
  isAboveTheFold: PropTypes.bool
};
