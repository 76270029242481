import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Ad } from '../../responsive/atoms/Ad';
import { LeftContentPackage } from './LeftContentPackage';
import { RightContentPackage } from './RightContentPackage/RightContentPackage';
import { GridColumn, GridRow } from '../../responsive/atoms/Grid';
import { theme } from '../../../domain/theme';
import { Container } from '../../responsive/atoms/Container';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { ContentPackageHeaderSection } from '../ContentPackage/ContentPackageHeaderSection';

const leftContainerBgColor = {
  black: '#222',
  white: '#f7f7f7',
  '#f7f7f7': 'white'
};

const styles = {
  leftContainer: css`
    display: flex;
    flex-direction: column;
  `,
  adMobile: css`
    margin: 0 auto 32px auto;
    ${theme.mq.tablet} {
      display: none;
    }
  `,
  adNonMobile: css`
    margin: 32px auto;
    display: none;
    ${theme.mq.tablet} {
      display: block;
    }
  `
};

const LeftAndRightContentPackageInner = ({
  data: rawData,
  content,
  isAboveTheFold
}) => {
  const data = {
    ...rawData,
    backgroundColor:
      rawData.backgroundColor === 'gray' ? '#f7f7f7' : rawData.backgroundColor
  };

  const hasHeaderSection = data.title || data.subtitle;

  const leftBackgroundColor =
    leftContainerBgColor[data.backgroundColor || 'black'];

  return (
    <Container
      margin="0 auto"
      padding={[
        '0 0 56px 0',
        '0 24px 56px 24px',
        '0 24px 56px 24px',
        '0 56px 80px 56px'
      ]}
      backgroundColor={data.backgroundColor}
    >
      {hasHeaderSection && (
        <Container
          maxWidth="100rem"
          padding={['0 0 0 0', '0 0 0 0', '0 0 0 0', '0 56px']}
        >
          <GridRow gutter="none">
            <GridColumn width={12}>
              <ContentPackageHeaderSection
                title={data.title}
                description={data.subtitle}
              />
            </GridColumn>
          </GridRow>
        </Container>
      )}

      <Container
        maxWidth="100rem"
        padding={
          hasHeaderSection
            ? null
            : ['24px 0 0 0', '24px 0 0 0', '40px 0 0 0', '56px 0 0 0']
        }
      >
        <GridRow gutter={[null, '12px', '20px', '20px']}>
          {data.leftContentPackage && (
            <GridColumn
              width={[12, 5, 4, 4]}
              order={[2, 1]}
              childrenMarginBottom="48px"
            >
              <div css={styles.leftContainer}>
                <div css={styles.adMobile}>
                  <Ad
                    id={`left_right_package_mobile__${data.id}`}
                    content={content}
                  />
                </div>

                <LeftContentPackage
                  content={content}
                  data={data.leftContentPackage.entity}
                  backgroundColor={leftBackgroundColor}
                  hideDescription={!!data.hideDescription}
                  isAboveTheFold={isAboveTheFold}
                />
              </div>
            </GridColumn>
          )}
          {data.rightContentPackage && (
            <GridColumn
              width={[12, 7, 8, 8]}
              order={[1, 2]}
              childrenMarginBottom={['32px', '32px', '32px', '40px']}
            >
              <RightContentPackage
                content={content}
                data={data.rightContentPackage.entity}
                backgroundColor={data.backgroundColor}
                hideDescription={!!data.hideDescription}
                isAboveTheFold={isAboveTheFold}
              />
            </GridColumn>
          )}
        </GridRow>

        <GridRow>
          <GridColumn width={12}>
            <div css={styles.adNonMobile}>
              <Ad
                id={`left_right_package_non_mobile__${data.id}`}
                content={content}
              />
            </div>
          </GridColumn>
        </GridRow>
      </Container>
    </Container>
  );
};

LeftAndRightContentPackageInner.defaultProps = {
  isAboveTheFold: false
};

LeftAndRightContentPackageInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const LeftAndRightContentPackage = ParagraphWrapper(
  LeftAndRightContentPackageInner
);
